import { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '../hooks/useAuth';
import useUpdateReduxWithCallNotifications from '../hooks/useUpdateReduxWithCallNotifications';
import { useConfig } from '../hooks/useConfig';

import Loader from '../components/loader/Loader';

import { ccpSelectors } from '../../store/selectors';
import { ROUTE_PATHS } from './routePaths';

const Stats = lazy(
  () => import(/* webpackChunkName: 'stats' */ '../pages/Stats')
);
const ContactHistory = lazy(
  () =>
    import(/* webpackChunkName: 'contact-history' */ '../pages/ContactHistory')
);
const Navigation = lazy(
  () =>
    import(
      /* webpackChunkName: 'navigation' */ '../components/Navigation/Navigation'
    )
);

const MainRoutes = () => {
  const { logout } = useAuth();
  const { status } = useSelector(ccpSelectors.getVoiceChannelContact);

  const { env } = useConfig();

  const isOutboundCampaign = useSelector(ccpSelectors.getOutboundCallType);

  useEffect(() => {
    let mAgent: connect.Agent;
    connect.agent(function (agent) {
      mAgent = agent;
    });

    const handleBeforeUnload = () => {
      if (isOutboundCampaign !== 'outboundCampaign') {
        const states = mAgent.getAgentStates();
        const offlineStatus = states.filter(
          (state) => state.name === 'Offline'
        )[0];
        mAgent.setState(offlineStatus);
      }
      logout();
    };

    const handleUnload = () => {
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [isOutboundCampaign]);

  useUpdateReduxWithCallNotifications();

  if (!env) return null;

  return (
    <Suspense fallback={<Loader />}>

      {/* Only render the <Navigation> component when call history is also enabled 
        otherwise there would be one single item which would not make sense
      */}
      {env.enabledFeatures?.CALL_HISTORY && <Navigation />}

      <Switch>
        <Route exact path={ROUTE_PATHS.main.stats} component={Stats} />
        {status === 'Initial' && env.enabledFeatures?.CALL_HISTORY && (
          <Route exact path={ROUTE_PATHS.main.contactHistory}>
            <ContactHistory />
          </Route>
        )}
        <Redirect to={ROUTE_PATHS.main.stats} />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
