import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../../../../assets/styles/defaultTheme/constants';

const getColor = (theme: Theme, status?: string): string => {
  switch (status) {
    case 'Available':
      return theme.palette.secondary.main;
    case 'Offline':
      return theme.palette.grey[600];
    default:
      return theme.palette.warning.main;
  }
};

export const useOutboundCallStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    ...STYLE_CONSTANTS.FLEX.VERTICAL_CENTER,
    flexDirection: 'column',
    margin: '1rem',
    borderRadius: '.5rem',
    textAlign: 'center',
    backgroundColor: `${theme.palette.third?.main}12`,
    animation: `$activeCall 3000ms ${theme.transitions.easing.easeIn}`,
    animationIterationCount: 'infinite',
    padding: '.5rem 0',
  },
  circularProgress: {
    '&.MuiCircularProgress-root': {
      color: `${theme.palette.primary.main}80`,
      position: 'absolute',
      top: -5,
      left: -5,
      zIndex: 1,
    },
  },
  numberWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    width: '90%',
    margin: '1rem 0 .5rem',
  },
}));

export const useIncomingStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '1rem 1rem 0',
  },
  incomingWrapper: {
    display: 'flex',
    padding: '.5rem',
    backgroundColor: `${theme.palette.third?.main}14`,
    borderRadius: '.5rem .5rem 0 0',
  },
  incomingIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '2.2rem',
      alignSelf: 'center',
      color: theme.palette.third?.main,
    },
  },
  contactWrapper: {
    '& > p': {
      margin: '.5rem',
      fontWeight: 600,
      fontSize: '1rem',
    },

    '& > label': {
      color: theme.palette.third?.main,
      margin: '.5rem',
      textTransform: 'uppercase',
      fontSize: '.6rem',
      fontWeight: 500,
    },
  },
}));

export const useOngoingStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: '1rem',
    borderRadius: '.5rem',
    textAlign: 'center',
    paddingTop: '1px',
    backgroundColor: `${theme.palette.third?.main}12`,
    animation: `$activeCall 3000ms ${theme.transitions.easing.easeIn}`,
    animationIterationCount: 'infinite',
  },
  welcomeMessage: {
    ...STYLE_CONSTANTS.FLEX.TOTAL_CENTER,
    backgroundColor: (props: { status?: string }) =>
      `${getColor(theme, props?.status)}26`,
    margin: '1rem 1rem 0',
    textAlign: 'center',
    fontWeight: 500,
    padding: '1.5rem',
    borderRadius: '.3rem',
    color: (props: { status?: string }) => getColor(theme, props?.status),
    border: (props: { status?: string }) =>
      `.1rem solid ${getColor(theme, props?.status)}`,

    '& > svg': {
      fill: (props: { status?: string }) => getColor(theme, props?.status),
      marginRight: '1rem',
    },
  },
  customerEnded: {
    '&.MuiTypography-root': {
      ...STYLE_CONSTANTS.FLEX.TOTAL_CENTER,
      backgroundColor: `${theme.palette.warning.main}40`,
      border: `1px solid ${theme.palette.warning.main}`,
      padding: '.5rem 0',
      margin: '1rem 1rem 0',
      borderRadius: '.3rem',
      fontWeight: 500,
      fontSize: '1rem',
    },
  },
  onHoldWrapper: {
    backgroundColor: `${theme.palette.warning.main}12`,
    animation: `$onHoldCall 3000ms ${theme.transitions.easing.easeIn}`,
  },
  actionHeader: {
    '&.MuiTypography-root': {
      ...STYLE_CONSTANTS.FLEX.VERTICAL_CENTER,
      textTransform: 'uppercase',
      textAlign: 'left',
      padding: '1rem 1rem 0',
      fontWeight: 600,
      fontSize: '.8rem',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.3rem',
        height: '1.3rem',
      },
    },
  },
  contactWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    margin: '1rem 0',
    padding: '0 1rem',

    '& > span': {
      fontSize: '1rem',
    },
  },
  buttonWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    marginTop: '1rem',
  },
  '@keyframes activeCall': {
    '0%': {
      backgroundColor: `${theme.palette.third?.main}12`,
    },
    '50%': {
      backgroundColor: `${theme.palette.third?.main}25`,
    },
    '100': {
      backgroundColor: `${theme.palette.third?.main}12`,
    },
  },
  '@keyframes onHoldCall': {
    '0%': {
      backgroundColor: `${theme.palette.warning.main}12`,
    },
    '50%': {
      backgroundColor: `${theme.palette.warning.main}25`,
    },
    '100': {
      backgroundColor: `${theme.palette.warning.main}12`,
    },
  },
}));

export const useMissedStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: '1rem 1rem 0',
    borderRadius: '.5rem',
    paddingTop: '1px',
    textAlign: 'center',
    backgroundColor: `${theme.palette.error.main}12`,
    animation: `$activeEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  actionHeader: {
    '&.MuiTypography-root': {
      ...STYLE_CONSTANTS.FLEX.VERTICAL_CENTER,
      color: theme.palette.error.main,
      textTransform: 'uppercase',
      padding: '1rem 1rem 0',
      textAlign: 'left',
      fontWeight: 600,
      fontSize: '.8rem',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.3rem',
        height: '1.3rem',
      },
    },
  },
  contactWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    margin: '1rem 0',
    padding: '0 1rem',

    '& > span': {
      fontWeight: 500,
      fontSize: '1rem',
    },
  },
  '@keyframes activeEffect': {
    '0%': {
      backgroundColor: `${theme.palette.error.main}12`,
    },
    '50%': {
      backgroundColor: `${theme.palette.error.main}25`,
    },
    '100': {
      backgroundColor: `${theme.palette.error.main}12`,
    },
  },
}));

export const useButtonStyles = makeStyles((theme: Theme) => ({
  endCallButton: {
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: `${theme.palette.error.main}20`,
      },

      '& > svg': {
        marginRight: '.5rem',
        width: '1.4rem',
        height: '1.4rem',
      },
    },
  },
  missedButton: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      borderRadius: '0 0 .5rem .5rem',
      width: '100%',

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}25`,
      },
    },
  },
  actionButton: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      borderRadius: 0,
      width: '50%',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}25`,
      },

      '&:first-of-type': {
        borderBottomLeftRadius: '.5rem',
      },

      '&:last-of-type': {
        borderBottomRightRadius: '.5rem',
      },

      '& > svg': {
        marginRight: '.5rem',
        width: '1.4rem',
        height: '1.4rem',
      },
    },
  },
  incomingButton: {
    '&.MuiButton-root': {
      borderRadius: 0,
      width: 'calc(50% - .1rem)',
      marginRight: '.1rem',
      borderBottomLeftRadius: '.5rem',

      '& > svg': {
        marginRight: '.5rem',
        width: '1.4rem',
        height: '1.4rem',
      },
    },
  },
  incomingButtonReject: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.error.main,

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}40`,
      },
    },
  },
  incomingButtonAccept: {
    '&.MuiButton-root': {
      backgroundColor: `${theme.palette.third?.main}14`,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: `${theme.palette.third?.main}40`,
      },
    },
  },
}));
