import { AxiosPromise } from 'axios';
import apiPaths from './apiPaths';
import { axiosInstance } from './axiosClient';

const { users } = apiPaths;

export const usersApi: {
  getLanguage: () => AxiosPromise<string>;
  setLanguage: (language: string) => AxiosPromise;
  getCountryCode: () => AxiosPromise<string>;
  saveCountryCode: (countryCode: string) => AxiosPromise;
} = {
  getLanguage: () => axiosInstance.get(users.users + users.language),
  setLanguage: (language) =>
    axiosInstance.post(users.users + users.language, language),
  getCountryCode: () => axiosInstance.get(users.users + users.countryCode),
  saveCountryCode: (countryCode) =>
    axiosInstance.post(users.users + users.countryCode, countryCode),
};
